@import "~@clientio/rappid/rappid.css";
@import "bootstrap/dist/css/bootstrap.css";

html,
body {
  font-family: "Inter", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}

#root {
  height: 100vh;
  width: 100vw;
}

.container1 {
  width: 100%;
  height: 100%;
  display: flex;
}

.stencil {
  min-width: 300px;
  max-width: 300px;
  background-color: whitesmoke;
  position: relative;
}

.canvas {
  /* width: 100%;
  height: 100%; */
  width: 60%;
  height: 100%;
}

.toolbar {
  margin: 12px;
  background-color: whitesmoke;
}

button {
  padding: 4px;
  min-width: 80px;
}

.properties {
  min-width: 20%;
  max-width: 20%;
  position: relative;
  background-color: lightblue;
}

.joint-paper {
  border: 1px solid #a0a0a0;
}

/* port styling */
.available-magnet {
  fill: #5da271;
}

/* element styling */
.available-cell rect {
  stroke-dasharray: 5, 2;
}

@keyframes dashDraw {
  from {
    stroke-dashoffset: 10;
  }
}

.animated {
  stroke-dasharray: 5;
  animation: dashdraw 0.5s linear infinite;
}

.joint-stencil.joint-theme-default .group .elements {
  border: 0px solid black;
}

.joint-stencil.joint-theme-default .group {
  border: 0px solid black;
}

.joint-stencil.joint-theme-default .group .group-label {
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: bold;
  letter-spacing: 3px;
}
